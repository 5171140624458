import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckLarge from './assets/images/check-1.inline.svg'

import './styles.scss'

interface EbookContentProps {
  synopsis?: any;
  authorQuote?: string;
  authorImage?: string;
  author?: string;
  role?: string;
  contentList: Array<string>;
  slug?: string;
}

const EbookContent: React.FC<EbookContentProps> = ({
  authorQuote,
  authorImage,
  contentList,
  author,
  synopsis,
  role,
  slug
}) => {
  const html = synopsis.childMarkdownRemark ? synopsis.childMarkdownRemark.html : ''
  return (
    <section className="container bg-light-gray-3">
      {
        synopsis.childMarkdownRemark &&
        <div className="row">
          <div className="col-md-12 row">
            <div className="col-md-12 d-flex justify-content-center">
              <div
                id="content-ebook"
                className="markdown-content pb-7 synopsis"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <CheckLarge className="check-large" />
            </div>
          </div>
        </div>
      }
      {
        author && 
        <div className="row">
          <div className="col-md-3">
            <img src={authorImage} className="author-image" alt="" />
          </div>
          <div className="col-md-9 d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center">
              <h3 className="text-primary" >{author}, <span className="text-success">{role}</span></h3>
              <p className="text-dark-gray-2 lead">{authorQuote}</p>
            </div>
          </div>
        </div>
      }
      <div className="row">
        <div className={`col-md-12 ${contentList.length > 0 ? 'py-layout-3' : 'pt-layout-6'}`}>
          <h4 className="text-primary text-center p-7"> <FormattedMessage id="ebook.title_content" /> </h4>
          <div>
            <ul className="list-content">
              {contentList.map((item, index) => (
                <li key={index}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EbookContent;