import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss'

interface EbookHeroPymntsProps {
  title: string;
  subtitle?: string;
  img: string;
  summary: string;
  slug?: string;
  campaign: string;
  urlPdf: {
    ebookPdf: {
      localFile: {
        publicURL: string;
      }
    };
  };
  location: Location;
}

const EbookHeroPymnts: React.FC<EbookHeroPymntsProps> = ({title, subtitle, img, summary, slug, urlPdf, location, campaign}) => { 
  const scrollToBenefits = () =>{
    window.scrollTo({
      top: 760, 
      behavior: 'smooth'
    });
  };

  const sendAnalyticsEvent = () => {

    if(typeof window !== 'undefined') {
      window.gtag('event', `Descarga Ebook - ${campaign}`, {
        'event_category': 'Descargar ebook',
        'event_label': `ebook-industrias kushki talks`,
      })
    }

    return true
  }
  console.log(urlPdf)
  return (
    <section className={`hero bg-light-gray-3  ${slug.includes('industrias-que-transforman') ? 'pb-layout-3' :''}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-6 col-xs-12">
              <h2 className=" display-4 mb-layout-2 text-primary mt-layout-3 mt-md-0">
                <strong>{title}</strong>
                <br/>
              </h2>
              <p>
                {subtitle}
              </p>
              <p className="mt-2 mb-2 lead">
                {summary}
              </p>
              {
                  location.pathname.includes('industrias') ?
                    <div className="button-responsive-row mt-5">
                      <button className="btn--primary text-white" onClick={() => sendAnalyticsEvent()} >
                        <a className="link-info" href={urlPdf.localFile.publicURL} download>
                          <FormattedMessage id="ebook.btn_download_pdf"/>
                          <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                            <path d="M21.2784 15.2948V19.2948C21.2784 19.8252 21.0677 20.3339 20.6926 20.709C20.3175 21.0841 19.8088 21.2948 19.2784 21.2948H5.27838C4.74795 21.2948 4.23924 21.0841 3.86417 20.709C3.48909 20.3339 3.27838 19.8252 3.27838 19.2948V15.2948" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.27838 10.2948L12.2784 15.2948L17.2784 10.2948" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.2784 15.2948V3.2948" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>
                      </button>
                    </div>
                  :
                  <div className="button-responsive-row mt-5">
                    <button onClick={scrollToBenefits} className="btn--primary text-white btn--lg">
                      {
                        location.pathname.includes('gat') ?
                          <a href="#content-ebook" className="text-white link-info">
                            <FormattedMessage id="ebook.btn_download_gat" />
                          </a>
                        :
                          <a href="#content-ebook" className="text-white link-info">
                            <FormattedMessage id="ebook.btn_info" />
                          </a>
                      }
                    </button>
                  </div>
              }
            </div>
            <div className="col-md-6 col-lg-6 d-none d-md-block">
              <img src={img} alt="Ebook-hero" className={`${slug}`}  width={500}/>
            </div>
          </div>
        </div>
      </section>
  );
}

export default EbookHeroPymnts;