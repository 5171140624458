import React from 'react'
import { FormattedMessage } from 'react-intl'

import './styles.scss'

const EbookVideo: React.FC = () => {
  
  return (
    <section className="container py-layout-4">
        <h3 className="text-primary text-center pb-layout-2" >
          <FormattedMessage id="ebook.video.title" />
        </h3>
      <div className="row">
        <div className="col-12">
          <iframe className="ebook-video_main" width="560" height="315" enablejsapi={1} src="https://www.youtube.com/embed/zgaopcy6PpY?si=9ALpn-_2CbjyMxD7&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <h4 className=" py-layout-1 text-primary">
            <FormattedMessage id="ebook.video.bye_cash" />
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <iframe className="ebook-video"
          src="https://www.youtube.com/embed/E9FP7zLVzpY&rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          enablejsapi={1}
          allowfullscreen></iframe>
          <h4 className=" py-layout-1 text-primary">
            <FormattedMessage id="ebook.video.challenges" />
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <iframe className="ebook-video"
          src="https://www.youtube.com/embed/lFM44Mdi2CA&rel=0"
          title="YouTube video player"
          frameborder="0"
          enablejsapi={1}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
          <h4 className=" py-layout-1 text-primary">
            <FormattedMessage id="ebook.video.tendencies" />
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <iframe className="ebook-video"
          src="https://www.youtube.com/embed/NyKg-v3GcTU&rel=0"
          title="YouTube video player"
          frameborder="0"
          enablejsapi={1}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
          <h4 className=" py-layout-1 text-primary">
            <FormattedMessage id="ebook.video.resilence" />
          </h4>
        </div>
      </div>
    </section>
  )
}

export default EbookVideo