import React, {useState} from 'react';
import IndexLayout from '../layouts';
import { graphql } from 'gatsby';
import EbookEntry from '../components/Ebook/EbookEntry/EbookEntry';
import { LocaleTypes } from '../data/locales';
import EbookHero from '../components/Ebook/EbookHero/EbookHero';
import EbookHeroPymnts from '../components/Ebook/EbookHeroPymnts/EbookHeroPymnts';
import EbookContent from '../components/Ebook/EbookContent/EbookContent';
import Footer from '../components/Footer/Footer';
import EbookForm from '../components/Ebook/EbookForm/EbookForm';
import SEO from '../components/SEO/SEO';
import EbookVideo from '../components/Ebook/EbookVideo/Ebook.Video';
import EbooksGallery from '../components/Ebook/EbooksGallery/EbooksGallery';


interface EbookTemplateProps {
  data: {
    contentfulEbook: {
      title: string;
      subtitle?: string;
      summary: string;
      slug: string;
      contentList: string[];
      author?: string;
      role?: string;
      campaign: string;
      ebookPdf: {
        localFile: {
          publicURL: string;
        }
      }
      authorQuote?: {
        authorQuote: string;
      }
      ebookImage: {
        localFile: {
          publicURL: string;
        }
      }
      authorImage?: {
        localFile: {
          publicURL: string;
        }
      },
      synopsis?: {
        childMarkdownRemark: {
          html: string;
        }
      }
    };
    allContentfulEbook: any
  };
  pathContext: {
    locale: LocaleTypes;
  };
  location: Location;
}

 


const EbookTemplate: React.SFC<EbookTemplateProps> = ({ data, pathContext, location }) => {
  
  const content = (): React.ReactNode => {
    const { contentfulEbook } = data;
    const { allContentfulEbook } = data;
    return (
      <>
        <SEO title={contentfulEbook.title} />
        
        {
          data.contentfulEbook.slug.includes('perspectiva-fintech')?
            <EbookHero 
            title={contentfulEbook.title}
            subtitle={contentfulEbook.subtitle}
            summary={contentfulEbook.summary || ''}
            img={contentfulEbook.ebookImage.localFile.publicURL}
            />
          :
            <EbookHeroPymnts
              title={contentfulEbook.title}
              subtitle={contentfulEbook.subtitle}
              summary={contentfulEbook.summary || ''}
              img={contentfulEbook.ebookImage.localFile.publicURL}
              slug={data.contentfulEbook.slug}
              urlPdf={contentfulEbook.ebookPdf}
              location={location}
              campaign={contentfulEbook.campaign}
            />
          
        }
        
        {
          contentfulEbook.contentList &&
          <>
             <section className="bg-light-gray-3">
              <EbookContent 
                author={contentfulEbook.author || ''}
                role={contentfulEbook.role || ''}
                synopsis={contentfulEbook.synopsis || ''}
                authorQuote={contentfulEbook.authorQuote ? contentfulEbook.authorQuote.authorQuote : ''}
                contentList={contentfulEbook.contentList  || []} 
                authorImage={contentfulEbook.authorImage ? contentfulEbook.authorImage.localFile.publicURL  : ''} 
                slug={data.contentfulEbook.slug}
              />
            </section>
            
          </>
        }
        
        <EbooksGallery allContentfulEbooks={allContentfulEbook}/>

        {
          location.pathname.includes('industrias') ?
            <EbookVideo />
          :
            <EbookForm urlPdf={contentfulEbook.ebookPdf} campaign={contentfulEbook.campaign} />
        }


        <EbookEntry data={data.blogPosts} />

        <Footer theme="primary" />
      </>
    )
  }
  return (
    <IndexLayout
      render={ content }
      locale={ pathContext.locale }
      location={ location}
    />
  )
}

export default EbookTemplate;

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulEbook(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      subtitle
      slug
      contentList
      author
      role
      summary
      campaign
      ebookPdf {
        localFile {
          publicURL
        }
      }
      authorQuote {
        authorQuote
      }
      ebookImage {
        localFile {
          publicURL
        }
      }
      authorImage {
        localFile {
          publicURL
        }
      }
      synopsis {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulEbook(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          id
          campaign
          slug
          title
          subtitle
          ebookPdf {
            file {
              url
            }
          }
          ktalks
          ebookImage {
            file {
              url
            }
          }
        }
      }
    }
    blogPosts: 
    allContentfulBlogPost(sort: {order: DESC, fields: date}, filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          title
          slug
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          mainImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;