import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'

interface EbooksI {
  allContentfulEbooks: {
    edges:any
  }
}

const EbooksGallery: FC <EbooksI>= ({allContentfulEbooks}) => {

  function filtrarDuplicadosPorTitulo(filtrados: any[]) {
    const titulosUnicos: string[] = [];
    const resultado: any[] = [];
  
    filtrados.forEach((filtrado) => {
      const tituloExistente = titulosUnicos.find((titulo) => titulo === filtrado.node.title);
      if (!tituloExistente) {
        titulosUnicos.push(filtrado.node.title);
        resultado.push(filtrado);
      }
    });
  
    return resultado;
  }

  const filtrarPorKTalks = (books: any) => {
    const resultado: any[] = [];
    books.map((book: any) => {
      if(book.node.ktalks === true){
        resultado.push(book)
      }
    })
    return resultado;
  }

  const sendAnalyticsEvent = () => {

    if(typeof window !== 'undefined') {
      window.gtag('event', `Descarga ebook`, {
        'event_category': 'Descargar ebook',
        'event_label': `ebook-industrias kushki talks`,
      })
    }

    return true
  }

  const ebooks = filtrarDuplicadosPorTitulo(allContentfulEbooks.edges)
  const ebooksPayments= filtrarPorKTalks(ebooks)

  return (
    <section className='container'>
      <div className="row py-7">
        <div className="col-12 px-7">
          <h3 className="text-center text-primary">
            <FormattedMessage id="ebook.gallery.title"/>
          </h3>
          <p className="text-center">
            <FormattedMessage id="ebook.gallery.desc"/>
          </p>
        </div>
        <div className="col-12 cards-container">
          {
            ebooksPayments.map((ebook: any) => {
                return (
                <div key={ebook.node.id} className='col-lg-3 p-4 card ebook-card'>
                  <h4 className='text-primary card-title'>{ebook.node.title}</h4>
                  <div className='ebook_image_container card-image'>
                    <img src={`http:${ebook.node.ebookImage.file.url}?h=150`} alt={ebook.node.title} className='ebook_image'/>
                  </div>
                  <button className="btn--primary text-white" onClick={() => sendAnalyticsEvent()}>
                    <a className="link-info" href={ebook.node.ebookPdf.file.url} download>
                      <FormattedMessage id="ebook.btn_download_pdf"/>
                    </a>
                  </button>
                </div>
                )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default EbooksGallery