import React, {FC} from 'react';
import { FormattedMessage } from 'react-intl';


import './styles.scss'

interface EbookHeroProps {
  title: string;
  subtitle?: string;
  img: string;
  summary: string;
}

const EbookHero: FC<EbookHeroProps> = ({title, subtitle, img, summary}) => { 
  return (
    <section className="hero bg-light-gray-3 bg-hero-ebook">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-6 col-xs-12">
              <h2 className=" display-4 mb-layout-2 text-primary mt-layout-3 mt-md-0">
                <strong>{title}</strong>
                <br/>
                <p>
                  {subtitle}
                </p>
              </h2>
              <p className="mt-2 mb-2 lead">
                {summary}
              </p>
              <div className="button-responsive-row mt-5">
                <button className="btn--basic btn--lg">
                  <a href="#content-ebook" className="text-dark-gray-2 link-info">
                    <FormattedMessage id="ebook.btn_info" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default EbookHero;