import React, {useState} from 'react'
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from 'axios';

import './styles.scss'

interface EbookFormProps {
  urlPdf: {
    ebookPdf: {
      localFile: {
        publicURL: string;
      }
    };
  };
  campaign: string;
}
type Inputs = {
  phone: string,
  company: string,
  // '00N3f000000i9hR': string,
  // url: string
  // '00N3f000000i9hW': string,
};
// '00N3f000000m0WS': string, // country

const EbookForm: React.FC<EbookFormProps> = ({urlPdf, campaign}) => {
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params =`first_name=${firstNameState.value}&last_name=${firstNameState.value}&email=${emailState.value}
    &00N3f000000m0WS=${''}&phone=${phoneState.value}&00N3f000000i9hR=${''}
    &company=${companyState.value}&url=${''}&00N3f000000i9hW=${''}&industry=${''}
    &00N3f000000i9hH=landing&00N3f000000i9hM=${campaign}&lead_source=Pagina_Web&00N3f000000m13W=${''}
    &oid=00D41000001P1n1`
    console.log(params)
    axios.post('https://hooks.zapier.com/hooks/catch/1396001/bth64a2?' + params, {}, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) =>{
    });
    setStep(2);
  };

  const [firstNameState, setFirstNameState] = useState({ active: false, value: ''});
  const [emailState, setEmailState] = useState({active: false, value: ''});
  const [phoneState, setPhoneState] = useState({active: false, value: ''});

  const [lastNameState, setLastNameState] = useState({active: false, value: ''});
  const [countryState, setCountryState] = useState({active: false, value: ''});
  const [industryState, setIndustryState] = useState({active: false, value: ''});
  const [companyState, setCompanyState] = useState({active: false, value: ''});
  const [btnNextState, setBtnNextState] = useState('disabled');
  const [btnSendState, setBtnSendState] = useState('disabled');
  const [validTextNState, setValidTextNState] = useState(false);
  const [validTextLState, setValidTextLState] = useState(false);
  const [validTextEState, setValidTextEState] = useState(false);
  const [validTextCSendState, setValidTextCSendState] = useState(false);
  const [validTextPhoneSendState, setValidTextPhoneSendState] = useState(false);
  const [validTextISendState, setValidTextISendState] = useState(false);


  const validateInputs = (val: any, inputName: string) => {
    switch (inputName) {
      case 'firstName':
        if (val === '' || val === undefined || val === null) {
          setFirstNameState({active: false, value: ''});
          setValidTextNState(true);
        } else {
          setFirstNameState({active: true, value: val});
          setValidTextNState(false);
        }
        break;
        case 'email':
          const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (val === '' || val === undefined || val === null || !reg.test(val)) {
            setEmailState({active: false, value: ''});
            setValidTextEState(true);
          } else {
            setEmailState({active: true, value: val});
            setValidTextEState(false);
          }
          break;
        case 'company':
          if (val === '' || val === undefined || val === null) {
            setCompanyState({active: false, value: ''});
            setValidTextCSendState(true);
          } else {
            setCompanyState({active: true, value: val});
            setValidTextCSendState(false);
          }
          break;
        case 'phone':
          if (val === '' || val === undefined || val === null) {
            setPhoneState({active: false, value: ''});
            setValidTextPhoneSendState(true);
          } else {
            setPhoneState({active: true, value: val});
            setValidTextPhoneSendState(false);
          }
          break;
        }
      enableButton();
      enableButtonSend();
  }

  const enableButton = () => {
    if ( emailState.active ) {
      setBtnNextState(false);
    } else {
      setBtnNextState('disabled');
      
    }
  }
  const enableButtonSend = () => {
    if ( companyState.active && phoneState.active &&  emailState.active && firstNameState.active) {
      setBtnSendState(false);
    } else {
      setBtnSendState('disabled');
    }
  }

  const sendAnalyticsEvent = () => {

    if(typeof window !== 'undefined') {
      window.gtag('event', `Clic - Formulario - ${campaign}`, {
        'event_category': 'Contacto Ventas',
        'event_label': `Contacto Ventas - Formulario`,
      })
    }

    return true
  }


  const { register, watch } = useForm<Inputs>();

  const [stepState, setStep] = useState(0);
  
  return (
    <section className="bg-form py-layout-4">
      <div className="container">
        <form >
          {
            stepState === 0 && (
            <div className="row">
            <div className="col-md-12">
              <h2 className="text-primary">
               <FormattedMessage id="ebook.complete_form" />
              </h2>
            </div>
              <div className="col-md-12 my-layout-1">
                <input
                  className={`form-control ${validTextNState && 'border-danger'}`}
                  id="first_name"
                  placeholder="Nombre y Apellido *"
                  maxLength="40"
                  onBlur={e => validateInputs(e.target.value, 'firstName')}
                  onChange={e => validateInputs(e.target.value, 'firstName')} />
                  {validTextNState && <div className="text-danger">Este campo es requerido</div>}
              </div>

              <div className="col-md-6 my-layout-1 ">
                <input className={`form-control ${validTextEState && 'border-danger'}`}
                  id="email"
                  placeholder="Correo electrónico de contacto*"
                  maxLength="80"
                  name="email"
                  onBlur={e => validateInputs(e.target.value, 'email')}
                  onChange={e => { validateInputs(e.target.value, 'email')} } />
                  {validTextEState && <div className="text-danger">Este campo es requerido o el formato es invalido</div>}
              </div>

              <div className="col-md-6 my-layout-1">
                <PhoneInput 
                  className={`form-control ${validTextPhoneSendState && 'border-danger'}`}
                  value={phoneState.value}
                  onChange={ (e) => validateInputs(e, 'phone') } 
                  defaultCountry="CO"
                  placeholder="Enter phone number"/>
                  {validTextEState && <div className="text-danger">Este campo es requerido o el formato es invalido</div>}
                {/* <input className="form-control"
                  id="phone"
                  placeholder="Teléfono"
                  maxLength="40"
                  {...register('phone')} /> */}
              </div>
              <div className="col-md-12 my-layout-1">
                  <input  id="company"
                    maxLength="40"
                    placeholder="Empresa"
                    className={`form-control`}
                    onBlur={e => validateInputs(e.target.value, 'company')}
                    onChange={e => { validateInputs(e.target.value, 'company')} } />
                </div>

              <div className="col-md-12 d-flex justify-content-center">
                <button type="button" onClick={ e => handleSubmit(e)} className="text-light btn--primary"  disabled={btnSendState}>
                  <FormattedMessage id="ebook.send_form"/>
                </button>
              </div>
          </div>)
          }
          {
           stepState === 2 && (
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center align-items-center text-center flex-column">
                <h2 className="text-primary">
                  <FormattedMessage id="ebook.title_final_step"/>
                </h2>
                <p>
                  <FormattedMessage id="ebook.final_step_text"/>
                </p>
                <button className="btn--basic" onClick={() => sendAnalyticsEvent()} >
                  <a href={urlPdf.localFile.publicURL} download>
                    <FormattedMessage id="ebook.btn_download_pdf"/>
                    <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                      <path d="M21.2784 15.2948V19.2948C21.2784 19.8252 21.0677 20.3339 20.6926 20.709C20.3175 21.0841 19.8088 21.2948 19.2784 21.2948H5.27838C4.74795 21.2948 4.23924 21.0841 3.86417 20.709C3.48909 20.3339 3.27838 19.8252 3.27838 19.2948V15.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.27838 10.2948L12.2784 15.2948L17.2784 10.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12.2784 15.2948V3.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  )
}

export default EbookForm
